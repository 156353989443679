<template>
  <div class="contract">
    <sideBar :topHeight="515"></sideBar>
    <div class="header">
      <Header :headSelectIndex='4'></Header>
    </div>
    <div class="banner">
      <div>
        <img src="../assets/img_pact320.png" alt="">
        <div class="word">
          <div>合同模板大全</div>
          <div>最全、最可靠的合同模板都在这里</div>
        </div>
      </div>

    </div>
    <div class="search">
      <el-input v-model="name" type="text" placeholder="请输入关键词"></el-input>
      <div class="btn" @click="search">搜索</div>
    </div>
    <div class="list" v-if="total>0">
      <div class="item" v-for="(item,index) of list" @click="goDetail(item.RecordGuid)">
        <div class="pic"><img :src="item.DocPreviewPic" alt=""><img src="../assets/icon_free20.png" alt=""></div>
        <div class="title">{{item.DocName}}</div>
        <div class="price"><span>¥ </span>9.9</div>
      </div>
    </div>
    <el-pagination @current-change="handleCurrentChange" :current-page.sync="PageIndex" :page-size="pageSize"
      :hide-on-single-page="hidePage" layout=" prev, pager, next,jumper" :total="total" v-if="total>0">
    </el-pagination>
    <div class="noList" v-if="total==0">
      <img src="../assets/pact_no_document100.png" alt="">
      <div>未搜索到相关合同模板 建议换个关键词再试</div>
    </div>
    <FootBox></FootBox>
  </div>
</template>
<script>
  import Header from '@/components/header'
  import FootBox from '@/components/FootBox'
  import sideBar from '@/components/sideBar'
  import {
    GetContractTemplates
  } from '@/api/index'
  export default {
    name: 'contract',
    data() {
      return {
        pageSize: 15,
        PageIndex: 1,
        total: 0,
        hidePage: true,
        list: [],
        name: ''
      }
    },
    mounted() {
      this.getList()
      _hmt.push(['_trackPageview', '/contract'])
      var that=this
      document.onkeydown=function (event){
        var e=event||window.event;
        if(e&&e.keyCode==13){
          that.search()
        }
      }
    },
    methods: {
      search() {
        _hmt.push(['_trackEvent', 'click', '搜索按钮', 'pc']);
        this.PageIndex=1
        this.list=[]
        this.getList()
      },
      getList() {
        var data = {
          "contractType": "",
          "contractName": this.name,
          "pageSize": this.pageSize,
          "pageIndex": this.PageIndex
        }
        var loading = this.$loading();
        GetContractTemplates(data).then(res => {
          console.log(res)
          loading.close();
          if (res.state == 0) {
            var list = res.data.list
            for (var i = 0; i < list.length; i++) {
              list[i].DocPreviewPic = list[i].DocPreviewPic == null || list[i].DocPreviewPic == '' ? require(
                '../assets/1.png') : list[i].DocPreviewPic

            }
            this.list = list
            this.total = res.data.count
          }
        })
      },
      goDetail(guid) {
        _hmt.push(['_trackEvent', 'click', '合同模板', 'pc']);
        this.$router.push('/contractDetail?guid=' + guid)
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.PageIndex = val
        this.getList()
      },
    },
    components: {
      Header,
      FootBox,
      sideBar
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    min-width: 940px;
    background: #21D0C8;
    height: 150px;
  }

  .banner {
    width: 100%;
    height: 320px;
    background: #DCF1FA;

    >div {
      position: relative;
      width: 940px;
      margin: 0 auto;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .word {
        position: absolute;
        top: 90px;
        left: 0;

        div:nth-child(1) {
          font-size: 46px;
          font-weight: bold;
          color: #57AAFB;
          margin-bottom: 10px;
        }

        div:nth-child(2) {
          font-size: 20px;
          color: #1A1A1A;
        }
      }
    }
  }

  .search {
    margin: 80px auto;
    width: 620px;
    height: 60px;
    border: 2px solid #EAEAEA;
    border-radius: 10px;
    box-sizing: border-box;

    .btn {
      display: inline-block;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-left: 2px solid #F3F3F3;
      width: 126px;
      font-size: 18px;
      color: #21D0C8;
      cursor: pointer;
    }
  }

  .list {
    width: 860px;
    margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;

    .item {
      width: 148px;
      cursor: pointer;
      margin-right: 30px;
      margin-bottom: 30px;
      display: inline-block;
      .pic {
        width: 148px;
        height: 210px;
        background: #FFFFFF;
        border: 1px solid #D2D2D2;
        border-radius: 10px;
        position: relative;
        margin-bottom: 10px;
        overflow: hidden;

        >img:nth-child(1) {
          width: 148px;
          height: 210px;
          border-radius: 10px;
          transition: all 0.5s;
        }

        >img:nth-child(2) {
          width: 70px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .title {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 10px;
      }

      .price {
        font-size: 20px;
        color: #F24537;

        span {
          font-size: 14px;
        }
      }
    }

    .item:nth-child(5n) {
      margin-right: 0;
    }

    .item:hover {
      .pic {
        border: 1px solid #21D0C8;

        >img:nth-child(1) {
          transform: scale(1.2);
        }
      }

      .title {
        color: #21D0C8;
      }


    }
  }

  .el-pagination {
    margin: 0 0 30px 0;
  }

  .noList {
    width: 140px;
    margin: 165px auto 198px;
    text-align: center;

    img {
      margin-bottom: 15px;
    }

    div {
      width: 140px;
      font-size: 14px;
      color: #AAAAAA;
      line-height: 17px;
    }
  }

</style>
<style lang="scss">
  .contract {

    .search .el-input,
    .search .el-input__inner {
      height: 56px;
      width: 488px;
      border: 0;
      border-radius: 10px;
      font-size: 18px;
    }

    .search .el-input__inner {
      padding: 0 30px;
    }
  }

</style>
